// from https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
import { stringify } from 'query-string';
import React, { RefObject, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { IQueryParams, ITrackEventProps, TGtagRemarketingIds } from 'typescriptDefs';

import { urlsOnboarding } from 'helpers/consts';
import { TLocale } from 'utils/i18n';

import { useScrolled } from '.';

const mobileBreakpoint = 992;
const tabletBreakpoint = 1200;

function useWindowWidth() {
  const [width, setWidth] = useState(typeof window === 'undefined' ? mobileBreakpoint : window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return width;
}

// returns a boolean specifying whether the screen is currently below the mobile break-point
const useIsMobileSize = () => useWindowWidth() < mobileBreakpoint;
const useIsTabletSize = () => useWindowWidth() < tabletBreakpoint;

function getMobileOperatingSystem() {
  if (typeof window !== `undefined`) {
    const userAgent = navigator.userAgent || navigator.vendor;

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/android/i.test(userAgent)) {
      return 'android';
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
      return 'ios';
    } else {
      return 'unknown';
    }
  } else {
    return 'unknown';
  }
}

const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false);
  React.useEffect(() => {
    const agent = getMobileOperatingSystem();
    setIsMobile(agent !== 'unknown');
  }, []);

  return isMobile;
};

const getQueryString = (queryParams: IQueryParams): string => {
  let stringHash = '',
    stringSearch = '';

  const queryParamsHashStringified = stringify(queryParams.hash);
  const queryParamsSearchStringified = stringify(queryParams.search);

  if (queryParamsHashStringified) {
    stringHash = `#${queryParamsHashStringified}`;
  }

  if (queryParamsSearchStringified) {
    stringSearch = `?${queryParamsSearchStringified}`;
  }

  return `${stringSearch}${stringHash}`;
};

export const getWebOnboardingLink = (queryParams: IQueryParams): string => {
  const queryString = getQueryString(queryParams);
  return `${urlsOnboarding.web}${queryString}`;
};

export const getOnboardingLink = (queryParams: IQueryParams): string => {
  const os = getMobileOperatingSystem();
  const platform = os === 'unknown' ? 'web' : os;

  const queryString = getQueryString(queryParams);
  return `${urlsOnboarding[platform]}${queryString}`;
};

const useGetQueryParams = (locale: TLocale, queryParams: IQueryParams): IQueryParams => {
  const isSegmentLoaded = useIsSegmentLoaded();
  const cookieQueryParams = getCookie('queryParamsSite');
  let params = {
    hash: queryParams.hash,
    search: { ...queryParams.search, language: locale },
  };

  if (cookieQueryParams) {
    params = {
      hash: { ...params.hash, ...cookieQueryParams.hash },
      search: { ...params.search, ...cookieQueryParams.search },
    };
  }

  if (isSegmentLoaded) {
    const { anonymousId } = window.analytics.user();
    params = {
      hash: params.hash,
      search: { ...params.search, af_sub3: anonymousId() },
    };
  }

  return params;
};

const getCookie = (name) => {
  const cookies = new Cookies();
  const cookie = cookies.get(name);
  return cookie;
};

// regex from https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
const reURL =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
const urlFormatValid = (url) => reURL.test(url);

const toTwoDigits = (input) => ('0' + input).slice(-2);

const trackEvent = (event: string, eventProps: ITrackEventProps): void => {
  if (typeof window !== 'undefined' && process.env.GATSBY_ENVIRONMENT === 'production') {
    if (window.analytics) {
      window.analytics.track(event, {
        page: window.location.href.split('?')[0],
        ...eventProps,
      });
    }
    if ((window as any).gtag) {
      (window as any).gtag('event', event, {
        page: window.location.href.split('?')[0],
        send_to: process.env.GATSBY_GA4_MEASUREMENT_ID,
        ...eventProps,
      });
    }
  }
};

// TO DEPRECATE
const trackEventUniversal = (
  event: string,
  {
    event_category = 'website-tracking',
    event_label,
    email,
  }: {
    event_category?: string;
    event_label?: string;
    value?: number;
    email?: string;
  },
) => {
  if (typeof window !== 'undefined' && process.env.GATSBY_ENVIRONMENT === 'production') {
    if (window.analytics) {
      window.analytics.track(event, {
        category: event_category,
        email,
        label: event_label,
        location: event_label,
        name: event_label,
        page: window.location.href.split('?')[0],
      });
    }
    if ((window as any).gtag) {
      (window as any).gtag('event', event, {
        category: event_category,
        email,
        label: event_label,
        location: event_label,
        name: event_label,
        page: window.location.href.split('?')[0],
        send_to: process.env.GATSBY_GA4_MEASUREMENT_ID,
      });
    }
    if (event === '"Get KOHO" clicked' || event === '"Sign up" clicked') {
      if ((window as any).snaptr) (window as any).snaptr('track', 'ADD_CART');
    }
  }
};

const useTrackEventSegmentLoaded = (copyTracking: { event: string; label: string; category: string }) => {
  const scrolled = useScrolled();
  const { event, label, category } = copyTracking;
  const [eventFired, setEventFired] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;

    if (!eventFired && window.analytics && event && label && category) {
      window.analytics.ready(() => {
        if (mounted) {
          setEventFired(true);
          trackEventUniversal(event, {
            event_label: label,
            event_category: category,
          });
        }
      });
    }

    return () => {
      mounted = false;
    };
  }, [event, label, category, eventFired, scrolled]);
};

const focusRef = (ref: RefObject<any>): void => {
  if (ref && ref.current) ref.current.focus();
};

const scrollToRef = (ref: RefObject<any>, offsetY?: number): void => {
  if (ref && ref.current) {
    ref.current.scrollIntoView();
    if (offsetY) window.scrollBy(0, offsetY);
  }
};

const setCookieExpiration = (daysToExpire) => {
  const dateCurrent = new Date();
  const dateExpiry = new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), dateCurrent.getDate() + daysToExpire);
  return dateExpiry;
};

const useIsLoaded = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);
  React.useEffect(() => {
    setIsLoaded(true);
  }, []);

  return isLoaded;
};

const generateString = (desiredLength: number): string => {
  let generatedString = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < desiredLength; i++) {
    generatedString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return generatedString;
};

const useIsSegmentLoaded = () => {
  const scrollYOffset = useScrolled();
  const [isSegmentLoaded, setIsSegmentLoaded] = useState<boolean>(false);

  useEffect(() => {
    let mounted = true;

    if (!isSegmentLoaded && window.analytics) {
      window.analytics.ready(() => {
        if (mounted) setIsSegmentLoaded(true);
      });
    }

    return () => {
      mounted = false;
    };
  }, [scrollYOffset, isSegmentLoaded]);

  return isSegmentLoaded;
};

const useGtagRemarketing = (id: TGtagRemarketingIds) => {
  useEffect(() => {
    if ((window as any).gtag) {
      (window as any).gtag('event', 'conversion', {
        send_to: `AW-902961551/${id}`,
        value: 1.0,
        currency: 'CAD',
        aw_remarketing_only: true,
      });
    }
  }, [id]);
};

const useMeasureInnerHeight = () => {
  const [innerHeight, setInnerHeight] = useState<number>(0);
  const handleResize = () => setInnerHeight(window.innerHeight);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return innerHeight;
};

const useIsWindowMethodLoaded = (method: string) => {
  const scrollYOffset = useScrolled();
  const [isWindowMethodLoaded, setIsWindowMethodLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!isWindowMethodLoaded && typeof window !== 'undefined' && window[method]) {
      setIsWindowMethodLoaded(true);
    }
  }, [method, scrollYOffset, isWindowMethodLoaded]);

  return isWindowMethodLoaded;
};

export {
  focusRef,
  generateString,
  getCookie,
  getMobileOperatingSystem,
  scrollToRef,
  setCookieExpiration,
  toTwoDigits,
  trackEvent,
  trackEventUniversal,
  urlFormatValid,
  useGetQueryParams,
  useGtagRemarketing,
  useIsLoaded,
  useIsMobile,
  useIsMobileSize,
  useIsSegmentLoaded,
  useIsTabletSize,
  useIsWindowMethodLoaded,
  useMeasureInnerHeight,
  useTrackEventSegmentLoaded,
  useWindowWidth,
};

export { default as getAfTracking } from './getAfTracking';
export { default as useScrolled } from './useScrolled';
