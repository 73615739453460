import { IQueryParams } from 'typescriptDefs';
import {
  TAfAdsetIds,
  TAfAdsetIdsBlogs,
  TAfAdsetIdsGeneral,
  TAfAdsetIdsPages,
  TAfAdsetsBlogs,
  TAfAdsetsPages,
  TAfChannels,
} from 'typescriptDefs/afTracking';

import { afAdsetsPages } from './consts';

export default (id: TAfAdsetIds): IQueryParams => ({
  hash: {},
  search: afTrackingMaster[id],
});

const trackAdsetPages = (adset: TAfAdsetsPages): { af_channel: TAfChannels; af_adset: TAfAdsetsPages } => ({
  af_channel: 'PAGES',
  af_adset: adset,
});

const trackAdsetBlogs = (adset: TAfAdsetsBlogs): { af_channel: TAfChannels; af_adset: TAfAdsetsBlogs } => ({
  af_channel: 'BLOGS',
  af_adset: adset,
});

export const generalAppsFlyerIds: Record<'NAV' | 'NAV_MOBILE' | 'NAV_SCROLLED' | 'SMART_BANNER', TAfAdsetIdsGeneral> = {
  ['NAV']: 'jaqreign',
  ['NAV_MOBILE']: 'og1ztkcu',
  ['NAV_SCROLLED']: '76yw0ndi',
  ['SMART_BANNER']: 'itfcoqv3',
};

const pageAppsFlyerIds: Record<TAfAdsetsPages, TAfAdsetIdsPages | undefined> = {
  ['404']: undefined,
  ['ABOUT']: 'npzb7y7i',
  ['ACCOUNTS']: '9mXpPBR4',
  ['AFFILIATES']: undefined,
  ['CAREERS']: undefined,
  ['CONTACT']: undefined,
  ['COVEROVERDRAFTPROTECTION']: 'iV7P0pdV',
  ['CREDIT-BUILDING']: 'wt0u8pmu',
  ['CREDIT-REPORT']: 'jsv52tzt',
  ['CULTURE']: undefined,
  ['CASH-BACK']: 'kvqdh8xw',
  ['FEATURES']: '7aqvwuw0',
  ['FINANCIAL_INSTITUTION_COMPARISON_TOOL']: 'kashfi81',
  ['FREECREDIT']: undefined,
  ['GOVERNMENT_BENEFITS']: undefined,
  ['HOME']: 'ka4ds90m',
  ['LEARN']: undefined,
  ['LEGAL']: undefined,
  ['LINE-OF-CREDIT']: undefined,
  ['METAL-CARD']: undefined,
  ['NEWTOCANADA']: 'Vykx1WoN',
  ['PAY-LATER']: undefined,
  ['POWERUPS']: 'Ty651Wom',
  ['REFERRAL']: '546p75R4',
  ['RENTERS']: undefined,
  ['SECURITY']: undefined,
  ['STATUS']: undefined,
  ['TRAVEL']: 'udm1t97r',
  ['VIRTUAL_CARD']: 'gxhwh8xw',
};

export const afTrackingByPage = (page: TAfAdsetsPages, customAdsetId?: string): IQueryParams => {
  const pageAdValues = trackAdsetPages(page);
  const adsetId = customAdsetId ?? pageAppsFlyerIds[page];

  return { hash: {}, search: { ...pageAdValues, af_adset_id: adsetId } };
};

export const afTrackingByPath = (pathname: string, customAdsetId?: string) => {
  return afTrackingByPage(afAdsetsPages[pathname], customAdsetId);
};

const afTrackingMaster: Record<TAfAdsetIdsPages | TAfAdsetIdsBlogs, IQueryParams['search']> = {
  ['npzb7y7i']: afTrackingByPage('ABOUT').search,
  ['9mXpPBR4']: afTrackingByPage('ACCOUNTS').search,
  ['iV7P0pdV']: afTrackingByPage('COVEROVERDRAFTPROTECTION').search,
  ['wt0u8pmu']: afTrackingByPage('CREDIT-BUILDING').search,
  ['jsv52tzt']: afTrackingByPage('CREDIT-REPORT').search,
  ['kvqdh8xw']: afTrackingByPage('CASH-BACK').search,
  ['7aqvwuw0']: afTrackingByPage('FEATURES').search,
  ['kashfi81']: afTrackingByPage('FINANCIAL_INSTITUTION_COMPARISON_TOOL').search,
  ['ka4ds90m']: afTrackingByPage('HOME').search,
  ['Vykx1WoN']: afTrackingByPage('NEWTOCANADA').search,
  ['Ty651Wom']: afTrackingByPage('POWERUPS').search,
  ['546p75R4']: afTrackingByPage('REFERRAL').search,
  ['udm1t97r']: afTrackingByPage('TRAVEL').search,
  ['gxhwh8xw']: afTrackingByPage('VIRTUAL_CARD').search,
  ['ignxr8wj']: { ...trackAdsetBlogs('88920ea4'), af_adset_id: 'ignxr8wj' },
  ['usks12d1']: { ...trackAdsetBlogs('88920ea4'), af_adset_id: 'usks12d1' },
  ['samthvzb']: { ...trackAdsetBlogs('b913da8f'), af_adset_id: 'samthvzb' },
  ['gwrmv08p']: { ...trackAdsetBlogs('b913da8f'), af_adset_id: 'gwrmv08p' },
  ['kidb44l8']: { ...trackAdsetBlogs('b913da8f'), af_adset_id: 'kidb44l8' },
  ['b03i4gkf']: { ...trackAdsetBlogs('e37ff2b4'), af_adset_id: 'b03i4gkf' },
  ['6uuafngz']: { ...trackAdsetBlogs('e37ff2b4'), af_adset_id: '6uuafngz' },
  ['pb63izx7']: { ...trackAdsetBlogs('e37ff2b4'), af_adset_id: 'pb63izx7' },
  ['chvqmx0c']: { ...trackAdsetBlogs('bf391fa7'), af_adset_id: 'chvqmx0c' },
  ['p5vdmbak']: { ...trackAdsetBlogs('bf391fa7'), af_adset_id: 'p5vdmbak' },
  ['ml9d6h0d']: { ...trackAdsetBlogs('bf391fa7'), af_adset_id: 'ml9d6h0d' },
  ['28lec722']: { ...trackAdsetBlogs('93ab630c'), af_adset_id: '28lec722' },
  ['1r5a5e21']: { ...trackAdsetBlogs('1c6d29f1'), af_adset_id: '1r5a5e21' },
  ['5tlzc78t']: { ...trackAdsetBlogs('a0fe9479'), af_adset_id: '5tlzc78t' },
  ['k4kc2i52']: { ...trackAdsetBlogs('a0fe9479'), af_adset_id: 'k4kc2i52' },
  ['1tjch666']: { ...trackAdsetBlogs('a0fe9479'), af_adset_id: '1tjch666' },
  ['dybntfx4']: { ...trackAdsetBlogs('a0fe9479'), af_adset_id: 'dybntfx4' },
  ['gnpxlhv9']: { ...trackAdsetBlogs('5faf3adb'), af_adset_id: 'gnpxlhv9' },
  ['biamtxd0']: { ...trackAdsetBlogs('caa48a63'), af_adset_id: 'biamtxd0' },
  ['ang2qo9y']: { ...trackAdsetBlogs('caa48a63'), af_adset_id: 'ang2qo9y' },
  ['hhr78d7h']: { ...trackAdsetBlogs('167f2872'), af_adset_id: 'hhr78d7h' },
  ['20cmei53']: { ...trackAdsetBlogs('167f2872'), af_adset_id: '20cmei53' },
  ['kt7te85i']: { ...trackAdsetBlogs('167f2872'), af_adset_id: 'kt7te85i' },
  ['rxfjfelb']: { ...trackAdsetBlogs('eaead1c2'), af_adset_id: 'rxfjfelb' },
  ['for52tyf']: { ...trackAdsetBlogs('eaead1c2'), af_adset_id: 'for52tyf' },
  ['axzym3m4']: { ...trackAdsetBlogs('e67e9575'), af_adset_id: 'axzym3m4' },
  ['fc0ir93i']: { ...trackAdsetBlogs('e67e9575'), af_adset_id: 'fc0ir93i' },
  ['jg3m782f']: { ...trackAdsetBlogs('e67e9575'), af_adset_id: 'jg3m782f' },
  ['dops01i1']: { ...trackAdsetBlogs('52d7bd65'), af_adset_id: 'dops01i1' },
  ['5ewfx8m3']: { ...trackAdsetBlogs('52d7bd65'), af_adset_id: '5ewfx8m3' },
  ['e49f97vc']: { ...trackAdsetBlogs('52d7bd65'), af_adset_id: 'e49f97vc' },
  ['d3wx9pu3']: { ...trackAdsetBlogs('ba3090c1'), af_adset_id: 'd3wx9pu3' },
  ['kad99pgf']: { ...trackAdsetBlogs('ba3090c1'), af_adset_id: 'kad99pgf' },
  ['4d6xo1bz']: { ...trackAdsetBlogs('ea2281e9'), af_adset_id: '4d6xo1bz' },
  ['89h88zvt']: { ...trackAdsetBlogs('ea2281e9'), af_adset_id: '89h88zvt' },
  ['seyq9x0c']: { ...trackAdsetBlogs('67e7b669'), af_adset_id: 'seyq9x0c' },
  ['j9d7exib']: { ...trackAdsetBlogs('67e7b669'), af_adset_id: 'j9d7exib' },
  ['2qz10n5x']: { ...trackAdsetBlogs('67e7b669'), af_adset_id: '2qz10n5x' },
  ['7glo2o76']: { ...trackAdsetBlogs('5213c9a3'), af_adset_id: '7glo2o76' },
  ['ybx9orlm']: { ...trackAdsetBlogs('5213c9a3'), af_adset_id: 'ybx9orlm' },
  ['uj27vl22']: { ...trackAdsetBlogs('5213c9a3'), af_adset_id: 'uj27vl22' },
  ['bc8a5cl8']: { ...trackAdsetBlogs('2ab15f11'), af_adset_id: 'bc8a5cl8' },
  ['x0hshzsx']: { ...trackAdsetBlogs('2ab15f11'), af_adset_id: 'x0hshzsx' },
  ['idwiasqg']: { ...trackAdsetBlogs('e9fca080'), af_adset_id: 'idwiasqg' },
  ['q1fglzs8']: { ...trackAdsetBlogs('e9fca080'), af_adset_id: 'q1fglzs8' },
  ['hrsehq49']: { ...trackAdsetBlogs('5416f815'), af_adset_id: 'hrsehq49' },
  ['iwxyskqp']: { ...trackAdsetBlogs('5416f815'), af_adset_id: 'iwxyskqp' },
  ['0mk2ujwz']: { ...trackAdsetBlogs('5416f815'), af_adset_id: '0mk2ujwz' },
  ['xoruuswe']: { ...trackAdsetBlogs('3f8403e3'), af_adset_id: 'xoruuswe' },
  ['esno2xcl']: { ...trackAdsetBlogs('7e131ff0'), af_adset_id: 'esno2xcl' },
  ['0p499lkk']: { ...trackAdsetBlogs('7e131ff0'), af_adset_id: '0p499lkk' },
  ['ptny3y7b']: { ...trackAdsetBlogs('7e131ff0'), af_adset_id: 'ptny3y7b' },
  ['cu227kwn']: { ...trackAdsetBlogs('a2ef4621'), af_adset_id: 'cu227kwn' },
  ['u4p5870y']: { ...trackAdsetBlogs('a2ef4621'), af_adset_id: 'u4p5870y' },
  ['zei49rr0']: { ...trackAdsetBlogs('a2ef4621'), af_adset_id: 'zei49rr0' },
  ['znf93jxf']: { ...trackAdsetBlogs('c64c39b6'), af_adset_id: 'znf93jxf' },
  ['td85xth2']: { ...trackAdsetBlogs('71243216'), af_adset_id: 'td85xth2' },
  ['k1iz5rdv']: { ...trackAdsetBlogs('71243216'), af_adset_id: 'k1iz5rdv' },
  ['teyoyyzq']: { ...trackAdsetBlogs('71243216'), af_adset_id: 'teyoyyzq' },
  ['meed20u6']: { ...trackAdsetBlogs('a66fb162'), af_adset_id: 'meed20u6' },
  ['if3vxclv']: { ...trackAdsetBlogs('a66fb162'), af_adset_id: 'if3vxclv' },
  ['yleqxsrf']: { ...trackAdsetBlogs('384221e7'), af_adset_id: 'yleqxsrf' },
  ['ni9c4owo']: { ...trackAdsetBlogs('384221e7'), af_adset_id: 'ni9c4owo' },
  ['7cqxbnlr']: { ...trackAdsetBlogs('d53da27c'), af_adset_id: '7cqxbnlr' },
  ['f3m7zjhn']: { ...trackAdsetBlogs('d53da27c'), af_adset_id: 'f3m7zjhn' },
  ['o3fuynzo']: { ...trackAdsetBlogs('53c10763'), af_adset_id: 'o3fuynzo' },
  ['qoovw56h']: { ...trackAdsetBlogs('53c10763'), af_adset_id: 'qoovw56h' },
  ['30m9pnz1']: { ...trackAdsetBlogs('6e30f003'), af_adset_id: '30m9pnz1' },
  ['j7jbbqpk']: { ...trackAdsetBlogs('6e30f003'), af_adset_id: 'j7jbbqpk' },
  ['1wis8bfy']: { ...trackAdsetBlogs('6938ac55'), af_adset_id: '1wis8bfy' },
  ['8q2uymf3']: { ...trackAdsetBlogs('6938ac55'), af_adset_id: '8q2uymf3' },
  ['mzgb2f28']: { ...trackAdsetBlogs('763c8145'), af_adset_id: 'mzgb2f28' },
  ['6zrky10e']: { ...trackAdsetBlogs('763c8145'), af_adset_id: '6zrky10e' },
  ['y8f7oo8p']: { ...trackAdsetBlogs('04bdbf53'), af_adset_id: 'y8f7oo8p' },
  ['lznq8hmm']: { ...trackAdsetBlogs('65e6db37'), af_adset_id: 'lznq8hmm' },
  ['647wlkdx']: { ...trackAdsetBlogs('65e6db37'), af_adset_id: '647wlkdx' },
  ['ho3rv95z']: { ...trackAdsetBlogs('eb35c626'), af_adset_id: 'ho3rv95z' },
  ['blws3x3c']: { ...trackAdsetBlogs('eb35c626'), af_adset_id: 'blws3x3c' },
  ['szqoju1p']: { ...trackAdsetBlogs('a413338c'), af_adset_id: 'szqoju1p' },
  ['n3la5wah']: { ...trackAdsetBlogs('a413338c'), af_adset_id: 'n3la5wah' },
  ['5iwq8qc9']: { ...trackAdsetBlogs('e3900229'), af_adset_id: '5iwq8qc9' },
  ['kfaucvv9']: { ...trackAdsetBlogs('e3900229'), af_adset_id: 'kfaucvv9' },
  ['z4k5elje']: { ...trackAdsetBlogs('ecf3f66f'), af_adset_id: 'z4k5elje' },
  ['fqubavre']: { ...trackAdsetBlogs('ecf3f66f'), af_adset_id: 'fqubavre' },
  ['fc7omvws']: { ...trackAdsetBlogs('bb1c66b7'), af_adset_id: 'fc7omvws' },
  ['akn44jfe']: { ...trackAdsetBlogs('bb1c66b7'), af_adset_id: 'akn44jfe' },
  ['nsfawxho']: { ...trackAdsetBlogs('bb1c66b7'), af_adset_id: 'nsfawxho' },
  ['vihx6pyu']: { ...trackAdsetBlogs('a5b4b22f'), af_adset_id: 'vihx6pyu' },
  ['31cupm2i']: { ...trackAdsetBlogs('a5b4b22f'), af_adset_id: '31cupm2i' },
  ['jlwdoui5']: { ...trackAdsetBlogs('68d119f3'), af_adset_id: 'jlwdoui5' },
  ['svryrttx']: { ...trackAdsetBlogs('68d119f3'), af_adset_id: 'svryrttx' },
  ['rx30v57k']: { ...trackAdsetBlogs('dc86b3dc'), af_adset_id: 'rx30v57k' },
  ['pvheczru']: { ...trackAdsetBlogs('dc86b3dc'), af_adset_id: 'pvheczru' },
  ['dfcbkk6j']: { ...trackAdsetBlogs('b22712c0'), af_adset_id: 'dfcbkk6j' },
  ['npjzeyev']: { ...trackAdsetBlogs('b22712c0'), af_adset_id: 'npjzeyev' },
  ['civ0dhxg']: { ...trackAdsetBlogs('cb37fc07'), af_adset_id: 'civ0dhxg' },
  ['6kjy94ev']: { ...trackAdsetBlogs('cb37fc07'), af_adset_id: '6kjy94ev' },
  ['zxqlwwra']: { ...trackAdsetBlogs('70115bac'), af_adset_id: 'zxqlwwra' },
  ['ea4d8xsd']: { ...trackAdsetBlogs('70115bac'), af_adset_id: 'ea4d8xsd' },
  ['b4jbsj5d']: { ...trackAdsetBlogs('be97b484'), af_adset_id: 'b4jbsj5d' },
  ['za75pdcm']: { ...trackAdsetBlogs('be97b484'), af_adset_id: 'za75pdcm' },
  ['uzpw5jhk']: { ...trackAdsetBlogs('fc95504b'), af_adset_id: 'uzpw5jhk' },
  ['3yf0dinu']: { ...trackAdsetBlogs('fc95504b'), af_adset_id: '3yf0dinu' },
  ['mldp939y']: { ...trackAdsetBlogs('fc95504b'), af_adset_id: 'mldp939y' },
  ['l0fxviry']: { ...trackAdsetBlogs('ac6866a3'), af_adset_id: 'l0fxviry' },
  ['0nyxzmls']: { ...trackAdsetBlogs('ac6866a3'), af_adset_id: '0nyxzmls' },
  ['0tq7r1by']: { ...trackAdsetBlogs('ac6866a3'), af_adset_id: '0tq7r1by' },
  ['ukqf03r5']: { ...trackAdsetBlogs('69064182'), af_adset_id: 'ukqf03r5' },
  ['4eh530j2']: { ...trackAdsetBlogs('69064182'), af_adset_id: '4eh530j2' },
  ['w8g4ftng']: { ...trackAdsetBlogs('69064182'), af_adset_id: 'w8g4ftng' },
  ['png6dv94']: { ...trackAdsetBlogs('43f8072f'), af_adset_id: 'png6dv94' },
  ['u8z3r4t4']: { ...trackAdsetBlogs('43f8072f'), af_adset_id: 'u8z3r4t4' },
  ['9gmv39yl']: { ...trackAdsetBlogs('5494b021'), af_adset_id: '9gmv39yl' },
  ['i3qwnren']: { ...trackAdsetBlogs('5494b021'), af_adset_id: 'i3qwnren' },
  ['9zvlzl7n']: { ...trackAdsetBlogs('505141ad'), af_adset_id: '9zvlzl7n' },
  ['6nd50wp5']: { ...trackAdsetBlogs('824b0302'), af_adset_id: '6nd50wp5' },
  ['ii4447wz']: { ...trackAdsetBlogs('3e9396e7'), af_adset_id: 'ii4447wz' },
  ['8kxcpg21']: { ...trackAdsetBlogs('da105660'), af_adset_id: '8kxcpg21' },
  ['hh4g79wi']: { ...trackAdsetBlogs('da105660'), af_adset_id: 'hh4g79wi' },
  ['0ndrngy3']: { ...trackAdsetBlogs('e483404c'), af_adset_id: '0ndrngy3' },
  ['czx3nlik']: { ...trackAdsetBlogs('e483404c'), af_adset_id: 'czx3nlik' },
  ['p3esyac8']: { ...trackAdsetBlogs('99c78902'), af_adset_id: 'p3esyac8' },
  ['8sw8e50d']: { ...trackAdsetBlogs('99c78902'), af_adset_id: '8sw8e50d' },
  ['snkjbb21']: { ...trackAdsetBlogs('19057ea5'), af_adset_id: 'snkjbb21' },
  ['6b31xdli']: { ...trackAdsetBlogs('19057ea5'), af_adset_id: '6b31xdli' },
  ['v1obsg0m']: { ...trackAdsetBlogs('d1532555'), af_adset_id: 'v1obsg0m' },
  ['rrnlcbjt']: { ...trackAdsetBlogs('d1532555'), af_adset_id: 'rrnlcbjt' },
  ['egw5tdyg']: { ...trackAdsetBlogs('61675e92'), af_adset_id: 'egw5tdyg' },
  ['ris4wcg8']: { ...trackAdsetBlogs('61675e92'), af_adset_id: 'ris4wcg8' },
  ['7v3zh9ou']: { ...trackAdsetBlogs('61675e92'), af_adset_id: '7v3zh9ou' },
  ['dkc82t78']: { ...trackAdsetBlogs('d71b6fbe'), af_adset_id: 'dkc82t78' },
  ['xsxgsawx']: { ...trackAdsetBlogs('d71b6fbe'), af_adset_id: 'xsxgsawx' },
  ['2s7k6uu3']: { ...trackAdsetBlogs('efb5e2bb'), af_adset_id: '2s7k6uu3' },
  ['ut15mdox']: { ...trackAdsetBlogs('efb5e2bb'), af_adset_id: 'ut15mdox' },
  ['28vpw9yw']: { ...trackAdsetBlogs('ea608af9'), af_adset_id: '28vpw9yw' },
  ['o7wb8br2']: { ...trackAdsetBlogs('ea608af9'), af_adset_id: 'o7wb8br2' },
  ['6kqpranx']: { ...trackAdsetBlogs('8c462f66'), af_adset_id: '6kqpranx' },
  ['nnobazw1']: { ...trackAdsetBlogs('5edc10b3'), af_adset_id: 'nnobazw1' },
  ['km7do2gk']: { ...trackAdsetBlogs('9ce4b9ca'), af_adset_id: 'km7do2gk' },
  ['nu3wpgtp']: { ...trackAdsetBlogs('9ce4b9ca'), af_adset_id: 'nu3wpgtp' },
  ['2l6pn411']: { ...trackAdsetBlogs('3ff1e268'), af_adset_id: '2l6pn411' },
  ['yyo53duf']: { ...trackAdsetBlogs('3ff1e268'), af_adset_id: 'yyo53duf' },
  ['ca9wzlrk']: { ...trackAdsetBlogs('58f31c55'), af_adset_id: 'ca9wzlrk' },
  ['ic1e7kpv']: { ...trackAdsetBlogs('58f31c55'), af_adset_id: 'ic1e7kpv' },
  ['dnbi27ch']: { ...trackAdsetBlogs('5ddcd9fa'), af_adset_id: 'dnbi27ch' },
  ['wjo0gfri']: { ...trackAdsetBlogs('5ddcd9fa'), af_adset_id: 'wjo0gfri' },
  ['1ej87qsi']: { ...trackAdsetBlogs('54ac0dc7'), af_adset_id: '1ej87qsi' },
  ['wcv2cqlo']: { ...trackAdsetBlogs('54ac0dc7'), af_adset_id: 'wcv2cqlo' },
  ['3hq9z2r9']: { ...trackAdsetBlogs('8653957b'), af_adset_id: '3hq9z2r9' },
  ['stalpans']: { ...trackAdsetBlogs('8653957b'), af_adset_id: 'stalpans' },
  ['9iee7ack']: { ...trackAdsetBlogs('a9c1aea3'), af_adset_id: '9iee7ack' },
  ['25krrbx2']: { ...trackAdsetBlogs('a9c1aea3'), af_adset_id: '25krrbx2' },
  ['yjpfi6nd']: { ...trackAdsetBlogs('79cba746'), af_adset_id: 'yjpfi6nd' },
  ['ehri36b9']: { ...trackAdsetBlogs('79cba746'), af_adset_id: 'ehri36b9' },
  ['ygrkrksz']: { ...trackAdsetBlogs('2dae5dee'), af_adset_id: 'ygrkrksz' },
  ['uqelpthi']: { ...trackAdsetBlogs('2dae5dee'), af_adset_id: 'uqelpthi' },
  ['u21ewyxw']: { ...trackAdsetBlogs('037280e2'), af_adset_id: 'u21ewyxw' },
  ['b1gjyonz']: { ...trackAdsetBlogs('826c5d24'), af_adset_id: 'b1gjyonz' },
  ['2a1s0c1r']: { ...trackAdsetBlogs('63c2208d'), af_adset_id: '2a1s0c1r' },
  ['tdkkq6gk']: { ...trackAdsetBlogs('88332532'), af_adset_id: 'tdkkq6gk' },
  ['551uzb1o']: { ...trackAdsetBlogs('88332532'), af_adset_id: '551uzb1o' },
  ['z6ktl5gb']: { ...trackAdsetBlogs('56095c7e'), af_adset_id: 'z6ktl5gb' },
  ['1e0r0awr']: { ...trackAdsetBlogs('7d13df34'), af_adset_id: '1e0r0awr' },
  ['3ot3u1fy']: { ...trackAdsetBlogs('7d13df34'), af_adset_id: '3ot3u1fy' },
  ['8her9biu']: { ...trackAdsetBlogs('00fa2e29'), af_adset_id: '8her9biu' },
  ['d8vri9no']: { ...trackAdsetBlogs('00fa2e29'), af_adset_id: 'd8vri9no' },
  ['gbb1l5sn']: { ...trackAdsetBlogs('4afce88e'), af_adset_id: 'gbb1l5sn' },
  ['t8kvg0i0']: { ...trackAdsetBlogs('4afce88e'), af_adset_id: 't8kvg0i0' },
  ['4xxu40io']: { ...trackAdsetBlogs('c0e79194'), af_adset_id: '4xxu40io' },
  ['hrqnriem']: { ...trackAdsetBlogs('c0e79194'), af_adset_id: 'hrqnriem' },
  ['6w1nmqxi']: { ...trackAdsetBlogs('c0e79194'), af_adset_id: '6w1nmqxi' },
  ['pzsay7dk']: { ...trackAdsetBlogs('2d3849c0'), af_adset_id: 'pzsay7dk' },
  ['jkby5xl2']: { ...trackAdsetBlogs('2d3849c0'), af_adset_id: 'jkby5xl2' },
  ['57fort7d']: { ...trackAdsetBlogs('63e88ae9'), af_adset_id: '57fort7d' },
  ['zirocwlk']: { ...trackAdsetBlogs('63e88ae9'), af_adset_id: 'zirocwlk' },
  ['22pwk3b5']: { ...trackAdsetBlogs('63e88ae9'), af_adset_id: '22pwk3b5' },
  ['p6kaw126']: { ...trackAdsetBlogs('0e129059'), af_adset_id: 'p6kaw126' },
  ['v2eh575a']: { ...trackAdsetBlogs('0e129059'), af_adset_id: 'v2eh575a' },
  ['74anknrn']: { ...trackAdsetBlogs('75ecc10e'), af_adset_id: '74anknrn' },
  ['hjuzmedh']: { ...trackAdsetBlogs('75ecc10e'), af_adset_id: 'hjuzmedh' },
  ['okfta82s']: { ...trackAdsetBlogs('8b1eea6f'), af_adset_id: 'okfta82s' },
  ['ly1cjkar']: { ...trackAdsetBlogs('8b1eea6f'), af_adset_id: 'ly1cjkar' },
  ['h18d07a5']: { ...trackAdsetBlogs('8b1eea6f'), af_adset_id: 'h18d07a5' },
  ['9ndxtg73']: { ...trackAdsetBlogs('add29c79'), af_adset_id: '9ndxtg73' },
  ['bde6a523']: { ...trackAdsetBlogs('add29c79'), af_adset_id: 'bde6a523' },
  ['xaemv1oo']: { ...trackAdsetBlogs('31ca0d9a'), af_adset_id: 'xaemv1oo' },
  ['d3wweucx']: { ...trackAdsetBlogs('31ca0d9a'), af_adset_id: 'd3wweucx' },
  ['mx2bmecm']: { ...trackAdsetBlogs('31ca0d9a'), af_adset_id: 'mx2bmecm' },
  ['x7qfzqao']: { ...trackAdsetBlogs('0120b257'), af_adset_id: 'x7qfzqao' },
  ['706tewds']: { ...trackAdsetBlogs('0120b257'), af_adset_id: '706tewds' },
  ['2n27zdnl']: { ...trackAdsetBlogs('bed2ab93'), af_adset_id: '2n27zdnl' },
  ['ui2iyv1e']: { ...trackAdsetBlogs('bed2ab93'), af_adset_id: 'ui2iyv1e' },
  ['05kvk7r5']: { ...trackAdsetBlogs('e7332f27'), af_adset_id: '05kvk7r5' },
  ['khpp10ml']: { ...trackAdsetBlogs('11f24147'), af_adset_id: 'khpp10ml' },
  ['lwjo2kpm']: { ...trackAdsetBlogs('11f24147'), af_adset_id: 'lwjo2kpm' },
  ['z2koqr77']: { ...trackAdsetBlogs('11f24147'), af_adset_id: 'z2koqr77' },
  ['bh7khqrf']: { ...trackAdsetBlogs('c097d91d'), af_adset_id: 'bh7khqrf' },
  ['1iafdwud']: { ...trackAdsetBlogs('c097d91d'), af_adset_id: '1iafdwud' },
  ['t5rswmvi']: { ...trackAdsetBlogs('01fbb9ba'), af_adset_id: 't5rswmvi' },
  ['300g6ovx']: { ...trackAdsetBlogs('01fbb9ba'), af_adset_id: '300g6ovx' },
  ['79vw1cwj']: { ...trackAdsetBlogs('01fbb9ba'), af_adset_id: '79vw1cwj' },
  ['785gvc5e']: { ...trackAdsetBlogs('244d1bc9'), af_adset_id: '785gvc5e' },
  ['gtdpt0zm']: { ...trackAdsetBlogs('244d1bc9'), af_adset_id: 'gtdpt0zm' },
  ['jkbyx7xe']: { ...trackAdsetBlogs('05213946'), af_adset_id: 'jkbyx7xe' },
  ['k0p2igk0']: { ...trackAdsetBlogs('05213946'), af_adset_id: 'k0p2igk0' },
  ['9ofq00uf']: { ...trackAdsetBlogs('05213946'), af_adset_id: '9ofq00uf' },
  ['vu3cc4p8']: { ...trackAdsetBlogs('81c3b59d'), af_adset_id: 'vu3cc4p8' },
  ['q2pe5sr1']: { ...trackAdsetBlogs('81c3b59d'), af_adset_id: 'q2pe5sr1' },
  ['8vtff56r']: { ...trackAdsetBlogs('6293b877'), af_adset_id: '8vtff56r' },
  ['fvtq20xi']: { ...trackAdsetBlogs('1c0019bf'), af_adset_id: 'fvtq20xi' },
  ['vecx2rhb']: { ...trackAdsetBlogs('edecc49d'), af_adset_id: 'vecx2rhb' },
  ['xlhj78aq']: { ...trackAdsetBlogs('edecc49d'), af_adset_id: 'xlhj78aq' },
  ['fd3whxpc']: { ...trackAdsetBlogs('5c6f5ce7'), af_adset_id: 'fd3whxpc' },
  ['03pjw6kn']: { ...trackAdsetBlogs('5c6f5ce7'), af_adset_id: '03pjw6kn' },
  ['pcy5xbik']: { ...trackAdsetBlogs('16f19143'), af_adset_id: 'pcy5xbik' },
  ['b99884ba']: { ...trackAdsetBlogs('230da653'), af_adset_id: 'b99884ba' },
  ['4hnfqclb']: { ...trackAdsetBlogs('230da653'), af_adset_id: '4hnfqclb' },
  ['j4mtteu1']: { ...trackAdsetBlogs('aff137ec'), af_adset_id: 'j4mtteu1' },
  ['x17mo4co']: { ...trackAdsetBlogs('bd91d953'), af_adset_id: 'x17mo4co' },
  ['htm2cpyl']: { ...trackAdsetBlogs('087b8688'), af_adset_id: 'htm2cpyl' },
  ['baaxr2rj']: { ...trackAdsetBlogs('087b8688'), af_adset_id: 'baaxr2rj' },
  ['wngrgft1']: { ...trackAdsetBlogs('4c0be771'), af_adset_id: 'wngrgft1' },
  ['q7z504o2']: { ...trackAdsetBlogs('4c0be771'), af_adset_id: 'q7z504o2' },
  ['8w54wuop']: { ...trackAdsetBlogs('920e9e25'), af_adset_id: '8w54wuop' },
  ['xdbm1muv']: { ...trackAdsetBlogs('920e9e25'), af_adset_id: 'xdbm1muv' },
  ['e53yueyq']: { ...trackAdsetBlogs('7026d829'), af_adset_id: 'e53yueyq' },
  ['g6d2awag']: { ...trackAdsetBlogs('7026d829'), af_adset_id: 'g6d2awag' },
  ['pjqhrmxc']: { ...trackAdsetBlogs('7026d829'), af_adset_id: 'pjqhrmxc' },
  ['fpw77a8h']: { ...trackAdsetBlogs('4d2bec95'), af_adset_id: 'fpw77a8h' },
  ['a8c3stp2']: { ...trackAdsetBlogs('4d2bec95'), af_adset_id: 'a8c3stp2' },
  ['5e8phkjf']: { ...trackAdsetBlogs('4d2bec95'), af_adset_id: '5e8phkjf' },
  ['nlc32cbk']: { ...trackAdsetBlogs('ed18742e'), af_adset_id: 'nlc32cbk' },
  ['yqike3pl']: { ...trackAdsetBlogs('ed18742e'), af_adset_id: 'yqike3pl' },
  ['4swcwwe5']: { ...trackAdsetBlogs('7e8d6225'), af_adset_id: '4swcwwe5' },
  ['383eo72c']: { ...trackAdsetBlogs('7e8d6225'), af_adset_id: '383eo72c' },
  ['5160ztq3']: { ...trackAdsetBlogs('02592c88'), af_adset_id: '5160ztq3' },
  ['diug04u0']: { ...trackAdsetBlogs('02592c88'), af_adset_id: 'diug04u0' },
  ['01j4n4ll']: { ...trackAdsetBlogs('02592c88'), af_adset_id: '01j4n4ll' },
  ['4a84n7y4']: { ...trackAdsetBlogs('e3237ec8'), af_adset_id: '4a84n7y4' },
  ['1ziceq7m']: { ...trackAdsetBlogs('e3237ec8'), af_adset_id: '1ziceq7m' },
  ['kb4rfapk']: { ...trackAdsetBlogs('9ff10dc9'), af_adset_id: 'kb4rfapk' },
  ['8k41s56v']: { ...trackAdsetBlogs('9ff10dc9'), af_adset_id: '8k41s56v' },
  ['lfw0j2j8']: { ...trackAdsetBlogs('fb7cf2a0'), af_adset_id: 'lfw0j2j8' },
  ['lhtqehpx']: { ...trackAdsetBlogs('fb7cf2a0'), af_adset_id: 'lhtqehpx' },
  ['2jesa30e']: { ...trackAdsetBlogs('7653b85f'), af_adset_id: '2jesa30e' },
  ['8zhbtn14']: { ...trackAdsetBlogs('7653b85f'), af_adset_id: '8zhbtn14' },
  ['phmlf1in']: { ...trackAdsetBlogs('24626713'), af_adset_id: 'phmlf1in' },
  ['xwiomwak']: { ...trackAdsetBlogs('24626713'), af_adset_id: 'xwiomwak' },
  ['6684gh5y']: { ...trackAdsetBlogs('24626713'), af_adset_id: '6684gh5y' },
  ['x1jea67k']: { ...trackAdsetBlogs('99a77731'), af_adset_id: 'x1jea67k' },
  ['mzcmo1cj']: { ...trackAdsetBlogs('0121a314'), af_adset_id: 'mzcmo1cj' },
  ['ryqr6h6u']: { ...trackAdsetBlogs('0121a314'), af_adset_id: 'ryqr6h6u' },
  ['dht0g6fv']: { ...trackAdsetBlogs('cacdefba'), af_adset_id: 'dht0g6fv' },
  ['kwnlzh7c']: { ...trackAdsetBlogs('cacdefba'), af_adset_id: 'kwnlzh7c' },
  ['8mnk7ony']: { ...trackAdsetBlogs('ad895192'), af_adset_id: '8mnk7ony' },
  ['ggoi9ryp']: { ...trackAdsetBlogs('ad895192'), af_adset_id: 'ggoi9ryp' },
  ['f8zs7jvi']: { ...trackAdsetBlogs('ad895192'), af_adset_id: 'f8zs7jvi' },
  ['p0ncj8dn']: { ...trackAdsetBlogs('f5be0c4f'), af_adset_id: 'p0ncj8dn' },
  ['k7o20k4v']: { ...trackAdsetBlogs('f5be0c4f'), af_adset_id: 'k7o20k4v' },
  ['iy6r6wyl']: { ...trackAdsetBlogs('10c4a994'), af_adset_id: 'iy6r6wyl' },
  ['stl4h0xm']: { ...trackAdsetBlogs('10c4a994'), af_adset_id: 'stl4h0xm' },
  ['unbkpg2x']: { ...trackAdsetBlogs('f31be353'), af_adset_id: 'unbkpg2x' },
  ['dihlifeb']: { ...trackAdsetBlogs('f31be353'), af_adset_id: 'dihlifeb' },
  ['sk9vb1a4']: { ...trackAdsetBlogs('799f2bff'), af_adset_id: 'sk9vb1a4' },
  ['qgmafzc8']: { ...trackAdsetBlogs('799f2bff'), af_adset_id: 'qgmafzc8' },
  ['w21jdncd']: { ...trackAdsetBlogs('799f2bff'), af_adset_id: 'w21jdncd' },
  ['zhrihjdy']: { ...trackAdsetBlogs('3fa77661'), af_adset_id: 'zhrihjdy' },
  ['sh09muza']: { ...trackAdsetBlogs('3fa77661'), af_adset_id: 'sh09muza' },
  ['iulsfckr']: { ...trackAdsetBlogs('3fa77661'), af_adset_id: 'iulsfckr' },
  ['j687x3wo']: { ...trackAdsetBlogs('04f60b22'), af_adset_id: '6684gh5y' },
  ['49u9u1c2']: { ...trackAdsetBlogs('04f60b22'), af_adset_id: '49u9u1c2' },
  ['o0u2r5r1']: { ...trackAdsetBlogs('0e77fb80'), af_adset_id: 'o0u2r5r1' },
  ['bwek4yx2']: { ...trackAdsetBlogs('0e77fb80'), af_adset_id: 'bwek4yx2' },
  ['lymdtvt0']: { ...trackAdsetBlogs('5e5fe0dd'), af_adset_id: 'lymdtvt0' },
  ['sxa7ts0o']: { ...trackAdsetBlogs('5e5fe0dd'), af_adset_id: 'sxa7ts0o' },
  ['61o82u67']: { ...trackAdsetBlogs('3af41d10'), af_adset_id: '61o82u67' },
  ['5o38pzhn']: { ...trackAdsetBlogs('3af41d10'), af_adset_id: '5o38pzhn' },
  ['jwgsknmq']: { ...trackAdsetBlogs('e93370ec'), af_adset_id: 'jwgsknmq' },
  ['zdaczfgt']: { ...trackAdsetBlogs('e93370ec'), af_adset_id: 'zdaczfgt' },
  ['67766jcq']: { ...trackAdsetBlogs('e93370ec'), af_adset_id: '67766jcq' },
  ['0y786zsi']: { ...trackAdsetBlogs('a13d95b4'), af_adset_id: '0y786zsi' },
  ['sdsadwwz']: { ...trackAdsetBlogs('a13d95b4'), af_adset_id: 'sdsadwwz' },
  ['ioq01e7w']: { ...trackAdsetBlogs('a13d95b4'), af_adset_id: 'ioq01e7w' },
  ['98lsxbw5']: { ...trackAdsetBlogs('a13d95b4'), af_adset_id: '98lsxbw5' },
  ['w27z0jfd']: { ...trackAdsetBlogs('dc7b96be'), af_adset_id: 'w27z0jfd' },
  ['w8mxrf37']: { ...trackAdsetBlogs('dc7b96be'), af_adset_id: 'w8mxrf37' },
  ['ovto24y3']: { ...trackAdsetBlogs('07f55446'), af_adset_id: 'ovto24y3' },
  ['2ie00unq']: { ...trackAdsetBlogs('07f55446'), af_adset_id: '2ie00unq' },
  ['bne57kss']: { ...trackAdsetBlogs('07f55446'), af_adset_id: 'bne57kss' },
  ['wtuq5r94']: { ...trackAdsetBlogs('f96497d2'), af_adset_id: 'wtuq5r94' },
  ['ax2eiofu']: { ...trackAdsetBlogs('f96497d2'), af_adset_id: 'ax2eiofu' },
  ['8dvrm6gm']: { ...trackAdsetBlogs('f96497d2'), af_adset_id: '8dvrm6gm' },
  ['2fko3u0w']: { ...trackAdsetBlogs('e3bae4f8'), af_adset_id: '2fko3u0w' },
  ['2c43vb0v']: { ...trackAdsetBlogs('e3bae4f8'), af_adset_id: '2c43vb0v' },
  ['k10fsc1h']: { ...trackAdsetBlogs('c19e6339'), af_adset_id: 'k10fsc1h' },
  ['utn5mxcg']: { ...trackAdsetBlogs('c19e6339'), af_adset_id: 'utn5mxcg' },
  ['u0az3ymo']: { ...trackAdsetBlogs('df6bbdd4'), af_adset_id: 'u0az3ymo' },
  ['fe8q29ip']: { ...trackAdsetBlogs('df6bbdd4'), af_adset_id: 'fe8q29ip' },
  ['2qpokpwf']: { ...trackAdsetBlogs('db9954ba'), af_adset_id: '2qpokpwf' },
  ['mtype6s8']: { ...trackAdsetBlogs('db9954ba'), af_adset_id: 'mtype6s8' },
  ['be3iv2vq']: { ...trackAdsetBlogs('3a3a0086'), af_adset_id: 'be3iv2vq' },
  ['ywcilrke']: { ...trackAdsetBlogs('3a3a0086'), af_adset_id: 'ywcilrke' },
  ['p0nxtz8m']: { ...trackAdsetBlogs('3a3a0086'), af_adset_id: 'p0nxtz8m' },
  ['as3cer6q']: { ...trackAdsetBlogs('8c6e0b91'), af_adset_id: 'as3cer6q' },
  ['l05k72m6']: { ...trackAdsetBlogs('8c6e0b91'), af_adset_id: 'l05k72m6' },
  ['ykgdojvg']: { ...trackAdsetBlogs('443d2914'), af_adset_id: 'ykgdojvg' },
  ['90utdm90']: { ...trackAdsetBlogs('443d2914'), af_adset_id: '90utdm90' },
  ['ey4uulif']: { ...trackAdsetBlogs('e5911775'), af_adset_id: 'ey4uulif' },
  ['0p4dy6zx']: { ...trackAdsetBlogs('e5911775'), af_adset_id: '0p4dy6zx' },
  ['f9yfuuue']: { ...trackAdsetBlogs('05710e86'), af_adset_id: 'f9yfuuue' },
  ['oyugd5t2']: { ...trackAdsetBlogs('05710e86'), af_adset_id: 'oyugd5t2' },
  ['amj3ycsr']: { ...trackAdsetBlogs('05710e86'), af_adset_id: 'amj3ycsr' },
  ['37vfgo7o']: { ...trackAdsetBlogs('e5b0ea80'), af_adset_id: '37vfgo7o' },
  ['edfig3uc']: { ...trackAdsetBlogs('e5b0ea80'), af_adset_id: 'edfig3uc' },
  ['r2ogzimg']: { ...trackAdsetBlogs('d1ba1d96'), af_adset_id: 'r2ogzimg' },
  ['j0zb2zuk']: { ...trackAdsetBlogs('d1ba1d96'), af_adset_id: 'j0zb2zuk' },
  ['29hjq98c']: { ...trackAdsetBlogs('b9b5983c'), af_adset_id: '29hjq98c' },
  ['neblwr75']: { ...trackAdsetBlogs('b9b5983c'), af_adset_id: 'neblwr75' },
  ['zemlvfo9']: { ...trackAdsetBlogs('ea150c66'), af_adset_id: 'zemlvfo9' },
  ['yjj7z8yx']: { ...trackAdsetBlogs('ea150c66'), af_adset_id: 'yjj7z8yx' },
  ['4xqqdg93']: { ...trackAdsetBlogs('ea150c66'), af_adset_id: '4xqqdg93' },
  ['uzmej7wn']: { ...trackAdsetBlogs('56b7341c'), af_adset_id: 'uzmej7wn' },
  ['08n3858r']: { ...trackAdsetBlogs('56b7341c'), af_adset_id: '08n3858r' },
  ['zt3ab52n']: { ...trackAdsetBlogs('14a9cc93'), af_adset_id: 'zt3ab52n' },
  ['hboyrjkx']: { ...trackAdsetBlogs('14a9cc93'), af_adset_id: 'hboyrjkx' },
  ['t1kpwgd5']: { ...trackAdsetBlogs('752d4f12'), af_adset_id: 't1kpwgd5' },
  ['t09hrnnx']: { ...trackAdsetBlogs('752d4f12'), af_adset_id: 't09hrnnx' },
  ['h7r104n2']: { ...trackAdsetBlogs('2a22fd57'), af_adset_id: 'h7r104n2' },
  ['9ollu5kv']: { ...trackAdsetBlogs('2a22fd57'), af_adset_id: '9ollu5kv' },
  ['8xhwsa15']: { ...trackAdsetBlogs('beb660c7'), af_adset_id: '8xhwsa15' },
  ['p83zfwiu']: { ...trackAdsetBlogs('beb660c7'), af_adset_id: 'p83zfwiu' },
  ['ek8mdtf3']: { ...trackAdsetBlogs('1311cfef'), af_adset_id: 'ek8mdtf3' },
  ['78rgna8t']: { ...trackAdsetBlogs('1311cfef'), af_adset_id: '78rgna8t' },

  ['oq9yaeln']: { ...trackAdsetBlogs('0rgcb2mw'), af_adset_id: 'oq9yaeln' },
  ['2ta5v1z3']: { ...trackAdsetBlogs('0rgcb2mw'), af_adset_id: '2ta5v1z3' },
  ['t99fwj2t']: { ...trackAdsetBlogs('390ovbya'), af_adset_id: 't99fwj2t' },
  ['opjk3dee']: { ...trackAdsetBlogs('390ovbya'), af_adset_id: 'opjk3dee' },
  ['6rsjo6r5']: { ...trackAdsetBlogs('390ovbya'), af_adset_id: '6rsjo6r5' },
  ['vsob3uea']: { ...trackAdsetBlogs('ivr4u5mj'), af_adset_id: 'vsob3uea' },
  ['sl6va7r6']: { ...trackAdsetBlogs('ivr4u5mj'), af_adset_id: 'sl6va7r6' },
  ['qd4se2oy']: { ...trackAdsetBlogs('ivr4u5mj'), af_adset_id: 'qd4se2oy' },
  ['17jdytzb']: { ...trackAdsetBlogs('vqttb0y9'), af_adset_id: '17jdytzb' },
  ['dozv5qvu']: { ...trackAdsetBlogs('vqttb0y9'), af_adset_id: 'dozv5qvu' },
  ['v8a48xfm']: { ...trackAdsetBlogs('xw935q0f'), af_adset_id: 'v8a48xfm' },
  ['k37lmouj']: { ...trackAdsetBlogs('xw935q0f'), af_adset_id: 'k37lmouj' },
  ['1qefgk3w']: { ...trackAdsetBlogs('xw935q0f'), af_adset_id: '1qefgk3w' },
  ['b4u2oqde']: { ...trackAdsetBlogs('jszwca5t'), af_adset_id: 'b4u2oqde' },
  ['a34qcdwp']: { ...trackAdsetBlogs('jszwca5t'), af_adset_id: 'a34qcdwp' },
  ['veovx940']: { ...trackAdsetBlogs('ehmszyur'), af_adset_id: 'veovx940' },
  ['nyfo930z']: { ...trackAdsetBlogs('ehmszyur'), af_adset_id: 'nyfo930z' },
  ['ppv74h48']: { ...trackAdsetBlogs('ehmszyur'), af_adset_id: 'ppv74h48' },
  ['9ubzozma']: { ...trackAdsetBlogs('lg8fu9cb'), af_adset_id: '9ubzozma' },
  ['hmvn6c71']: { ...trackAdsetBlogs('lg8fu9cb'), af_adset_id: 'hmvn6c71' },
  ['kdumt4bj']: { ...trackAdsetBlogs('lg8fu9cb'), af_adset_id: 'kdumt4bj' },
  ['nu2zwse5']: { ...trackAdsetBlogs('0f1yu48z'), af_adset_id: 'nu2zwse5' },
  ['a9bcqcuh']: { ...trackAdsetBlogs('0f1yu48z'), af_adset_id: 'a9bcqcuh' },
  ['n5h250oj']: { ...trackAdsetBlogs('032v8aeu'), af_adset_id: 'n5h250oj' },
  ['wpxjaia2']: { ...trackAdsetBlogs('032v8aeu'), af_adset_id: 'wpxjaia2' },
  ['u1kl1298']: { ...trackAdsetBlogs('032v8aeu'), af_adset_id: 'u1kl1298' },
  ['m6g8wfke']: { ...trackAdsetBlogs('d57fxdh3'), af_adset_id: 'm6g8wfke' },
  ['5ba0us2r']: { ...trackAdsetBlogs('d57fxdh3'), af_adset_id: '5ba0us2r' },
  ['6tcne5ge']: { ...trackAdsetBlogs('9e6ha47a'), af_adset_id: '6tcne5ge' },
  ['6uqvyw8y']: { ...trackAdsetBlogs('9e6ha47a'), af_adset_id: '6uqvyw8y' },
  ['onm9lfdf']: { ...trackAdsetBlogs('qiug01pz'), af_adset_id: 'onm9lfdf' },
  ['4a6c11lw']: { ...trackAdsetBlogs('qiug01pz'), af_adset_id: '4a6c11lw' },
  ['i2ssx98i']: { ...trackAdsetBlogs('p4u2gf30'), af_adset_id: 'i2ssx98i' },
  ['pyl5pqfx']: { ...trackAdsetBlogs('p4u2gf30'), af_adset_id: 'pyl5pqfx' },
  ['0urf5sx3']: { ...trackAdsetBlogs('ef5snf0i'), af_adset_id: '0urf5sx3' },
  ['cttawvtw']: { ...trackAdsetBlogs('ef5snf0i'), af_adset_id: 'cttawvtw' },
  ['8hw72bqn']: { ...trackAdsetBlogs('g9213d2q'), af_adset_id: '8hw72bqn' },
  ['y5qldw0l']: { ...trackAdsetBlogs('g9213d2q'), af_adset_id: 'y5qldw0l' },
  ['l0mp4yhf']: { ...trackAdsetBlogs('g9213d2q'), af_adset_id: 'l0mp4yhf' },
  ['tulo3byh']: { ...trackAdsetBlogs('n0t522yr'), af_adset_id: 'tulo3byh' },
  ['9yssdqja']: { ...trackAdsetBlogs('n0t522yr'), af_adset_id: '9yssdqja' },
  ['rygoyxwf']: { ...trackAdsetBlogs('n0t522yr'), af_adset_id: 'rygoyxwf' },
  ['kv5ld62w']: { ...trackAdsetBlogs('5nggiptu'), af_adset_id: 'kv5ld62w' },
  ['u9rhrqq1']: { ...trackAdsetBlogs('5nggiptu'), af_adset_id: 'u9rhrqq1' },
  ['xr1qtdy7']: { ...trackAdsetBlogs('wsmdjxps'), af_adset_id: 'xr1qtdy7' },
  ['jfow88gf']: { ...trackAdsetBlogs('23j9up44'), af_adset_id: 'jfow88gf' },
  ['1a57r19x']: { ...trackAdsetBlogs('3pl8g18c'), af_adset_id: '1a57r19x' },
  ['gyw97sjj']: { ...trackAdsetBlogs('rnqhyhq8'), af_adset_id: 'gyw97sjj' },
  ['wwe0x5ff']: { ...trackAdsetBlogs('rnqhyhq8'), af_adset_id: 'wwe0x5ff' },
  ['0e3ecgl6']: { ...trackAdsetBlogs('kshj1k2g'), af_adset_id: '0e3ecgl6' },
  ['kpyvymwz']: { ...trackAdsetBlogs('kshj1k2g'), af_adset_id: 'kpyvymwz' },
  ['agb88wrw']: { ...trackAdsetBlogs('kshj1k2g'), af_adset_id: 'agb88wrw' },
  ['4gwkfs6g']: { ...trackAdsetBlogs('m5iluujf'), af_adset_id: '4gwkfs6g' },
  ['9gbm6g3r']: { ...trackAdsetBlogs('m5iluujf'), af_adset_id: '9gbm6g3r' },
  ['hser62i5']: { ...trackAdsetBlogs('8wzh45f5'), af_adset_id: 'hser62i5' },
  ['dgm48jck']: { ...trackAdsetBlogs('8wzh45f5'), af_adset_id: 'dgm48jck' },
  ['38d2cb88']: { ...trackAdsetBlogs('b75n1l47'), af_adset_id: '38d2cb88' },
  ['bss2273c']: { ...trackAdsetBlogs('b75n1l47'), af_adset_id: 'bss2273c' },
  ['rkd9hs7z']: { ...trackAdsetBlogs('b75n1l47'), af_adset_id: 'rkd9hs7z' },
  ['zdyd8fcu']: { ...trackAdsetBlogs('2ww6zfl0'), af_adset_id: 'zdyd8fcu' },
  ['36ygt4an']: { ...trackAdsetBlogs('2ww6zfl1'), af_adset_id: '36ygt4an' },
  ['g1jgpiyl']: { ...trackAdsetBlogs('2ww6zfl2'), af_adset_id: 'g1jgpiyl' },
  ['nwk50nxs']: { ...trackAdsetBlogs('f08hvzis'), af_adset_id: 'nwk50nxs' },
  ['471mb70c']: { ...trackAdsetBlogs('f08hvzis'), af_adset_id: '471mb70c' },
  ['q7bmvxy2']: { ...trackAdsetBlogs('f08hvzis'), af_adset_id: 'q7bmvxy2' },
  ['x4gcgnrx']: { ...trackAdsetBlogs('0z992z98'), af_adset_id: 'x4gcgnrx' },
  ['4g5spgjr']: { ...trackAdsetBlogs('0z992z98'), af_adset_id: '4g5spgjr' },
  ['c05i3klh']: { ...trackAdsetBlogs('0z992z98'), af_adset_id: 'c05i3klh' },
  ['egywvl67']: { ...trackAdsetBlogs('qjax8cpb'), af_adset_id: 'egywvl67' },
  ['lao4xso3']: { ...trackAdsetBlogs('qjax8cpb'), af_adset_id: 'lao4xso3' },
  ['qjoz07qh']: { ...trackAdsetBlogs('qjax8cpb'), af_adset_id: 'qjoz07qh' },
  ['hwuw2lwn']: { ...trackAdsetBlogs('lbqm7lix'), af_adset_id: 'hwuw2lwn' },
  ['wos1h9is']: { ...trackAdsetBlogs('lbqm7lix'), af_adset_id: 'wos1h9is' },
  ['shrggoz5']: { ...trackAdsetBlogs('fbedrpwz'), af_adset_id: 'shrggoz5' },
  ['xgci77xj']: { ...trackAdsetBlogs('fbedrpwz'), af_adset_id: 'xgci77xj' },
  ['qyrmqdp1']: { ...trackAdsetBlogs('fbedrpwz'), af_adset_id: 'qyrmqdp1' },
  ['nwvypf2x']: { ...trackAdsetBlogs('fc3ecy21'), af_adset_id: 'nwvypf2x' },
  ['gjgomj7u']: { ...trackAdsetBlogs('fc3ecy21'), af_adset_id: 'gjgomj7u' },
  ['x1krtidk']: { ...trackAdsetBlogs('fc3ecy21'), af_adset_id: 'x1krtidk' },
  ['gqeesxv9']: { ...trackAdsetBlogs('kd2m7szq'), af_adset_id: 'gqeesxv9' },
  ['6vikq3bd']: { ...trackAdsetBlogs('kd2m7szq'), af_adset_id: '6vikq3bd' },
  ['99vt3juw']: { ...trackAdsetBlogs('6f3hafdk'), af_adset_id: '99vt3juw' },
  ['x046o3p6']: { ...trackAdsetBlogs('6f3hafdk'), af_adset_id: 'x046o3p6' },
  ['2bgpms95']: { ...trackAdsetBlogs('6f3hafdk'), af_adset_id: '2bgpms95' },
};
